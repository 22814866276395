import { render, staticRenderFns } from "./regions.vue?vue&type=template&id=0cb0e6f6&"
import script from "./regions.vue?vue&type=script&lang=js&"
export * from "./regions.vue?vue&type=script&lang=js&"
import style0 from "./regions.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QBtn,QTable,QTr,QTd,QPagination,QDialog,QCard,QForm,QCardSection,QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QInput,QIcon,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QTable,QTr,QTd,QPagination,QDialog,QCard,QForm,QCardSection,QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QInput,QIcon})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
